<template>
    <v-overlay :opacity="0.8" :value="loading" v-if="loading">
        <v-progress-circular indeterminate size="64" />
        <br />
        Searching...
    </v-overlay>
    <v-container v-else class="pa-6 d-flex align-start">
        <v-row>
            <!-- left gutter -->
            <v-col sm="0" md="1" lg="2"></v-col>
            <v-col sm="12" md="10" lg="8">
                <v-sheet
                    v-if="calcs.length > 0 || libraries.length > 0"
                    class="pa-4"
                >
                    <v-row class="text-h4 typography1--text mb-4">
                        <v-col
                            lg="6"
                            sm="12"
                            :class="
                                largeScreen
                                    ? 'd-flex flex-column align-start'
                                    : 'd-flex flex-column justify-center align-center'
                            "
                        >
                            <div>Search results for "{{ search }}"</div>
                            <div class="text-subtitle-2">
                                See
                                <a
                                    href="https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#bkmk_boolean"
                                    target="__blank"
                                    >here</a
                                >
                                for how to complete more in depth searches
                            </div>
                        </v-col>
                        <v-col
                            lg="6"
                            sm="12"
                            :class="
                                largeScreen
                                    ? 'd-flex justify-end'
                                    : 'd-flex justify-center'
                            "
                        >
                            <v-btn-toggle
                                v-model="toggleFilter"
                                rounded
                                mandatory
                                dense
                            >
                                <v-btn outlined color="red">All</v-btn>
                                <v-btn outlined color="red"
                                    >Libraries ({{ totalLibs }})</v-btn
                                >
                                <v-btn outlined color="red"
                                    >Calculations ({{ calcs.length
                                    }}<span v-if="calcsOverflow">+</span
                                    >)</v-btn
                                >
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <template v-if="showLibs">
                        <div class="pb-2 pt-4" style="font-size: 1.25rem">
                            {{
                                libraries.length > 0
                                    ? `Libraries (${totalLibs})`
                                    : showCalcs
                                    ? ``
                                    : `No libraries found for
                            "${search}".`
                            }}
                        </div>
                        <LibSearchCard
                            v-for="lib in libraries"
                            :key="lib.libraryId"
                            :library="lib"
                        />
                    </template>
                    <template v-if="showCalcs">
                        <div class="pb-2 pt-4" style="font-size: 1.25rem">
                            {{
                                calcs.length > 0
                                    ? `Calculations (${
                                          calcsOverflow ? 'Top ' : ''
                                      }${calcs.length} of ${totalCalcs})`
                                    : showLibs
                                    ? ``
                                    : `No calculations found for "${search}".`
                            }}
                        </div>
                        <CalcSearchCard
                            v-for="calc in calcs"
                            :calc="calc"
                            :key="calc.calcId"
                        ></CalcSearchCard>
                    </template>
                </v-sheet>
                <v-sheet class="d-flex justify-center" v-else>
                    <p class="text-h4 typography1--text">
                        No search results for "{{ search }}"
                    </p>
                </v-sheet>
            </v-col>
            <!-- right gutter -->
            <v-col sm="0" md="1" lg="2"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import CalcSearchCard from './CalcSearchCard.vue'
import LibSearchCard from './LibSearchCard.vue'

export default {
    name: 'search',
    data() {
        return {
            loading: true,
            calcs: [],
            totalCalcs: 0,
            calcsLimit: 50,
            libsLimit: 50,
            totalLibs: 0,
            libraries: [],
            toggleFilter: 0,
        }
    },
    components: { CalcSearchCard, LibSearchCard },
    computed: {
        /**
         * Returns search value from URL
         */
        search() {
            if (this.$route.params.search) {
                return this.$route.params.search.replaceAll('-', ' ')
            }
            return ''
        },
        showCalcs() {
            return (this.toggleFilter === 0) | (this.toggleFilter === 2)
        },
        showLibs() {
            return (this.toggleFilter === 0) | (this.toggleFilter === 1)
        },
        calcsOverflow() {
            return this.totalCalcs > this.calcsLimit
        },
        largeScreen() {
            return (
                this.$vuetify.breakpoint.name === 'lg' ||
                this.$vuetify.breakpoint.name === 'xl'
            )
        },
    },
    mounted() {
        this.updatePage()
    },
    methods: {
        ...mapActions(['fullSearch']),
        /**
         * Update page with new search
         */
        async updatePage() {
            this.loading = true

            let { calcSearch, libSearch } = await this.fullSearch({
                search: this.search,
                calcsLimit: this.calcsLimit,
                libsLimit: this.libsLimit,
            })
            this.calcs = calcSearch.calcs
            this.totalCalcs = calcSearch.totalCalcs

            this.libraries = libSearch.libs
            this.totalLibs = libSearch.totalLibs

            this.loading = false
        },
    },
    watch: {
        /**
         * Update page when route/param change
         */
        $route(to, from) {
            if (to.name === 'search') {
                if (to.params.search !== from.params.search) {
                    this.updatePage(this.calculations)
                }
            }
        },
    },
}
</script>
